export const filterRelativeLinks = (link: string, market: string) => {
  return '/' + addTrailingSlash(
    link?.split('/')
    .filter((e) => e && e !== market)
    .join('/')
  );
}

export const getHref = () => {
  if (typeof window !== 'undefined') return window?.location?.href
  return ''
}

export const addTrailingSlash = (url: string) => {
  return url?.replace(/\/?$/, '/')
}

export const classNames = (...items: any) => items.filter((a: any) => a).join(' ')
