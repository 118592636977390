import React, { useState, useContext, useEffect } from 'react'
import { getCookie, setCookie, POLICY_COOKIE_NAME, POLICY_COOKIE_ACCEPT_AFTER_SECONDS, POLICY_COOKIE_TEXT } from '~src/helpers/cookieHelper'
import SettingsContext from '~src/helpers/settingsContext';
import Link from '~components/utils/link';

import './index.scss'

const timeout = POLICY_COOKIE_ACCEPT_AFTER_SECONDS * 1000

export default () => {
    // tslint:disable-next-line:variable-name
    const { settings: { content: { cookie_policy, legal_notices_link: cached_url, } = { cookie_policy: false, legal_notices_link: { cached_url: null } } } } = useContext(SettingsContext);

    if (!cookie_policy) return <></>

    const [show, setShow] = useState(false)
    const [timeoutStarted, setTimeoutStarted] = useState(false)
    const hideBannerAndSetCookie = () => {
        setShow(false)
        setCookie(POLICY_COOKIE_NAME, true)
    }

    useEffect(() => {
        setShow(!getCookie(POLICY_COOKIE_NAME));
        if (!timeoutStarted) {
            setTimeoutStarted(true)
            setTimeout(hideBannerAndSetCookie, timeout);
        }
    }, [cookie_policy]);

    return show ? (
        <div className='cookie-policy-message open'>
            <div className='cookie-policy-message__container'>
                <div className='cookie-policy-message__title'>Cookie Use</div>
                <div className='cookie-policy-message__message'>
                    {POLICY_COOKIE_TEXT}
                    <Link className='cookie-policy-message__link' storyblokLink={cached_url}>
                        Learn more
                    </Link>
                </div>
                <div className='cookie-policy-message__button' onClick={hideBannerAndSetCookie}> Got it<i className='cookie-policy-message__close-icon' />
                </div>
            </div>
        </div>
    ) : <></>
}
