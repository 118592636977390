export const getCookie = (name: string) => {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2) return parts.pop()?.split(';').shift();

    return null;
}

export const setCookie = (cname: string, cvalue: string, exdays = 10000) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export const POLICY_COOKIE_NAME = 'ACP'
export const POLICY_COOKIE_ACCEPT_AFTER_SECONDS = 10
export const POLICY_COOKIE_TEXT = 'We use cookies to give you the best possible experience. By continuing to browse our website, you agree to our use of cookies.'
