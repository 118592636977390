import { BreakpointContext } from '@ef-global/web-ui-react/lib/components/BreakpointContext';
import { css } from '@emotion/core';
import React, { useContext } from 'react';
import Link from '~components/utils/link';
import SettingsContext from '~src/helpers/settingsContext';
import IconFacebook from '@ef-global/web-ui-react/lib/icons/IconSocialFb'
import IconInsta from '@ef-global/web-ui-react/lib/icons/IconSocialIn'
import IconYoutube from '@ef-global/web-ui-react/lib/icons/IconSocialYt'
import IconLinkedIn from '@ef-global/web-ui-react/lib/icons/IconSocialLi'
import IconExternal from '@ef-global/web-ui-react/lib/icons/IconExternalLink'
import LogoSimple from '@ef-global/web-ui-react/lib/logos/LogoSimple'
import Logo from '@ef-global/web-ui-react/lib/logos/Logo'

const footerAdditionalStyles = css`
  padding: 24px 0 64px 0;
  background-color: #e5ebef;
`;

const Footer = (props: any) => {
  const { currentBp } = React.useContext(BreakpointContext);
  const { settings } = useContext(SettingsContext);

  const socialMediaLinks = settings.content && (
    <div className='ef-footer__social-list'>
      {settings.content.facebook_link?.url && (
        <Link className='ef-footer__social-link' storyblokLink={settings.content.facebook_link} aria-label='Facebook' openInANewTab>
          <IconFacebook className='ef-footer__icon' />
        </Link>
      )}
      {settings.content.instagram_link?.url && (
        <Link className='ef-footer__social-link' storyblokLink={settings.content.instagram_link} aria-label='Instagram' openInANewTab>
          <IconInsta className='ef-footer__icon' />
        </Link>
      )}
      {settings.content.youtube_link?.url && (
        <Link className='ef-footer__social-link' storyblokLink={settings.content.youtube_link} aria-label='Youtube' openInANewTab>
          <IconYoutube className='ef-footer__icon' />
        </Link>
      )}
      {settings.content.linkedin_link?.url && (
        <Link className='ef-footer__social-link' storyblokLink={settings.content.linkedin_link} aria-label='Linkedin' openInANewTab>
          <IconLinkedIn className='ef-footer__icon' />
        </Link>
      )}
    </div>
  );

  return (
    <footer className='footer' css={footerAdditionalStyles}>
      <div className='ef-container'>
        <div
          className={`ef-row -y-center ${
            currentBp !== 's'
              ? '-x-center u-mb-xxl u-mt-xxl'
              : '-x-between u-mb-l u-mt-l'
          } `}
        >
          <div className='ef-col -s-shrink'>
            <Link storyblokLink={settings.content?.ef_logo_link} aria-label='Homepage' openInANewTab>
              {currentBp === 's' ? <LogoSimple /> : <Logo />}
            </Link>
          </div>
          <div className='ef-col -s-shrink u-show-s-down'>
            {socialMediaLinks}
          </div>
        </div>
        <div className='ef-row -x-center'>
          <div className={`ef-col -s-12 -m-6`}>
            <p className={`u-f-light u-m-text-center`}>
              {settings.content?.footer_text}
            </p>
          </div>
        </div>
        <div className='ef-row -x-center u-show-m-up u-mb-xl u-mt-xl'>
          <div className='ef-col -s-shrink'>{socialMediaLinks}</div>
        </div>
        <div className='ef-row -x-center u-show-m-up u-mb-xl u-mt-xl'>
          <div className='ef-col -s-shrink'>
            <Link className='ef-footer__link' storyblokLink={settings.content?.legal_notices_link} openInANewTab>
              Legal notices&nbsp;
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
