import React, {useContext, useEffect, useRef} from 'react';
import Link from '~components/utils/link';
import {
  IStoryBlokComponent,
  IStoryblokLink,
} from '~interfaces/storyblock';
import SettingsContext from '~src/helpers/settingsContext';
import {AnimatedBurgerMenu} from './icons';
import LogoSimple from '@ef-global/web-ui-react/lib/logos/LogoSimple';
import {filterRelativeLinks} from '~helpers/generic-helpers';

interface ISubNavItem extends IStoryBlokComponent {
  name: string;
  link: IStoryblokLink;
  id: string;
  open_in_a_new_tab: boolean,
}

interface INavItem extends IStoryBlokComponent {
  name: string;
  link: IStoryblokLink;
  subNavItems: Array<ISubNavItem>;
  id: string;
  open_in_a_new_tab: boolean,
}

const Header = (props: any) => {
  const headerRef = useRef(null);
  const {settings} = useContext(SettingsContext);

  let gradient = settings.content?.header_gradient || '';
  const whiteLogo = settings.content?.white_logo;
  const existsSubmenu = settings.content?.main_nav.length > 0;
  const logoLink = settings.content?.ef_logo_link?.url === ''
    ? filterRelativeLinks(settings.content?.ef_logo_link?.cached_url, process.env.GATSBY_MARKET)
    : settings.content?.ef_logo_link?.url;

  if (settings.content?.white_gradient) {
    gradient = settings.content.header_gradient.replace('gradient', 'grd-white');
  }

  let EFHeader: any;
  useEffect(() => {
    import('@ef-global/web-ui/js/ef-header/ef-header').then((efheader) => {
      if (typeof window !== 'undefined') {
        EFHeader = new efheader.EfHeader(headerRef.current);
      }
    });
  });

  const hideHeader = () => EFHeader.hide();

  return (
    <header
      ref={headerRef}
      className={`ef-header -condensed -transparent ${gradient}`}
      data-type='condensed'
      style={{position: 'absolute'}}
    >
      <div className='ef-header__main'>
        <div className='ef-container ef-header__group -standalone'>
          {/* EF Logo */}
          <div className='ef-header__item ef-header__product'>
            <a className='ef-header__title' href={logoLink ? logoLink?.replace('//', '/') : '/'} aria-label={settings.content?.ef_logo_link}>
              <LogoSimple className={`ef-logo--simple${whiteLogo ? ' ef-header__logo' : ''}`}/>
            </a>
          </div>
          <button
            className={`${whiteLogo ? 'ef-header__menu-light ' : ''}ef-header__menu-toggle ef-nav__toggle`}
            aria-label='Open menu'
            style={{display: !existsSubmenu ? 'none' : undefined}} // Ideally we would not render this button but the header has an event listener and breaks if the button does not exist
          >
            <AnimatedBurgerMenu/>
          </button>
          <div className='ef-header__group -nav'>
            <nav
              className={`ef-nav ef-header__item${!whiteLogo ? ' ef-nav__dark' : ''}`}
              // Fixes iOS bottom drawer issue
            >
              <ul className='ef-nav__menu'>
                {existsSubmenu && settings.content.main_nav.map((navItem: INavItem) => (
                  <li
                    className='ef-nav__item -with-sub-menu'
                    key={navItem._uid}
                  >
                    <Link
                      className='ef-nav__link'
                      storyblokLink={navItem.link}
                      onClick={hideHeader}
                      openInANewTab={navItem.open_in_a_new_tab}
                    >
                      {navItem.name}
                    </Link>
                    {navItem.subNavItems.length ? (
                        <button
                          className='ef-collapsible__toggle -icon u-show-s-only ef-nav__sub-menu-toggle'
                          data-target={`#nav-${navItem._uid}`}
                          data-collapsed='false'
                        />
                      )
                      : <></>
                    }
                    {navItem.subNavItems.length ? (
                      <ul
                        className='ef-nav__sub-menu ef-collapsible__content'
                        id={`nav-${navItem._uid}`}
                      >
                        {navItem.subNavItems.length > 0 && navItem.subNavItems.map(
                          (subNavItem: ISubNavItem) => (
                            <li
                              className='ef-nav__sub-item'
                              key={subNavItem._uid}
                            >
                              <Link
                                storyblokLink={subNavItem.link}
                                fragment={subNavItem.id}
                                className='ef-nav__sub-link'
                                onClick={hideHeader}
                                openInANewTab={subNavItem.open_in_a_new_tab}

                              >
                                {subNavItem.name}
                              </Link>
                            </li>
                          ),
                        )}
                      </ul>
                    ) : <></>
                    }
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
