import React from 'react';
import { IStoryBlokComponent } from '~interfaces/storyblock';

export interface ISettingsContext {
    set: () => void;
    settings: any;
  }

const defaultContextValue: ISettingsContext = {
   set: () => null,
   settings: {},
 };

const SettingsContext = React.createContext(defaultContextValue);

interface ISettingsContextProviderState {
  set: any;
  settings: IStoryBlokComponent;
}

class SettingsContextProvider extends React.Component<any, ISettingsContextProviderState> {
   constructor(props: ISettingsContext) {
    super(props);

    this.setSettings = this.setSettings.bind(this);
    this.state = {
       set: this.setSettings,
       settings: props.settings || {},
     };
   }

   public render() {
    return (
      <SettingsContext.Provider value={this.state}>
        {this.props.children}
      </SettingsContext.Provider>
    );
  }

   private setSettings(newSettings: any) {
     this.setState({
       settings: newSettings,
     });
   }
 }

export { SettingsContext as default, SettingsContextProvider };
