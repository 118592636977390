import { BreakpointProvider } from '@ef-global/web-ui-react/lib/components/BreakpointContext';
import React from 'react';
import { SettingsContextProvider } from '~src/helpers/settingsContext';
import BlankLayout from './blankLayout';
import Layout from './layout';

export default (props: any) => {
  const { children, pageContext } = props;
  let settings = {...pageContext.globalSettings};
  settings = settings.node;

  if (typeof settings?.content === 'string') {
    settings.content = JSON.parse(settings.content);
  }

  if (pageContext.layout === 'blank') {
    return (
      <BreakpointProvider>
        <BlankLayout pageContext={pageContext} settings={settings}>
          {children}
        </BlankLayout>
      </BreakpointProvider>
    );
  }
  return (
    <BreakpointProvider>
      <SettingsContextProvider settings={settings}>
        <Layout pageContext={pageContext} settings={settings}>
          {children}
        </Layout>
      </SettingsContextProvider>
    </BreakpointProvider>
  );
};
