import { Link as GatsbyLink } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import { IStoryblokLink } from '~interfaces/storyblock';
import { isProduction } from './env-helpers';
import { filterRelativeLinks } from '~/src/helpers/generic-helpers';

interface ILink {
  storyblokLink: IStoryblokLink;
  children: any;
  activeClassName?: any;
  target?: any;
  fragment?: string;
  queryString?: string;
  [x: string]: any;
  openInANewTab: boolean;
}

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const Link = ({
  storyblokLink,
  children,
  activeClassName,
  target,
  fragment,
  queryString = '',
  openInANewTab,
  ...other
}: ILink) => {
  // If no link is provided then returns a div element
  if (!storyblokLink?.cached_url && !storyblokLink?.url) {
    return (
      <div {...other}>{children}</div>
    );
  }

  let newCachedUrl = storyblokLink.cached_url || '/';

  if (isProduction()) {
    // Remove the folder directory from relative links
    // @ts-ignore
    newCachedUrl = filterRelativeLinks(newCachedUrl, process.env.GATSBY_MARKET);
  }

  const regexAbsoluteURL = /^https?:\/\//i;

  if (target === '_blank' || regexAbsoluteURL.test(storyblokLink?.url)) {
    return (
      <OutboundLink
        href={(storyblokLink?.url || newCachedUrl) + queryString}
        target='_blank'
        rel='noopener'
        {...other}
      >
        {children}
      </OutboundLink>
    );
  }
  // Use Gatsby Link for internal links, and <a> for others
  if (storyblokLink?.linktype === 'story') {
    const toPage = newCachedUrl === 'home' ? '' : newCachedUrl;
    const toFragment = fragment ? `#${fragment}` : ''
    const to = `/${toPage}${toFragment}${queryString}/`
      .replace('//', '/')
      .replace('/#', '#')
      .replace(/(#.*)(\/$)/, (_match, p1) => {
        return p1
      })

    return (
      <GatsbyLink
        to={to.replace('//', '/').replace('/#', '#')}
        activeClassName={activeClassName}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a href={storyblokLink?.url + queryString} {...other} target={openInANewTab ? '_blank' : undefined}>
      {children}
    </a>
  );
};

export default Link;
