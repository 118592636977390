import React from 'react';

import Header from '~components/custom/header';
import Footer from '~components/web-ui/footer';
import CookieBanner from '~components/custom/cookie-banner'
import { IStoryBlokComponent } from '~interfaces/storyblock';
import '~src/styles/main.scss';


interface ILayoutProps {
  pageContext: any;
  settings: IStoryBlokComponent;
}

const Layout: React.SFC<ILayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      <main style={{ minHeight: '100vh' }}>{children}</main>
      <CookieBanner />
      <Footer />
    </>
  );
};

export default Layout;
