// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-templates-hf-js": () => import("./../../../src/templates/hf.js" /* webpackChunkName: "component---src-templates-hf-js" */),
  "component---src-templates-iec-js": () => import("./../../../src/templates/iec.js" /* webpackChunkName: "component---src-templates-iec-js" */),
  "component---src-templates-storyblok-entry-js": () => import("./../../../src/templates/storyblok-entry.js" /* webpackChunkName: "component---src-templates-storyblok-entry-js" */)
}

